<script>
export default {
  props: {
    props: Object,
  },
};
</script>

<template>
  <div class="section-with-simple-list py-5 mt-5">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="font-weight-bold">{{ props.title }}</h2>
      </div>
      <div class="col-12 text-center">
        <p class="lead text-muted">{{ props.description }}</p>
      </div>
      <div class="col-12 my-5">
        <div
          class="card mb-3 border-0 rounded bg-light"
          v-for="(item, index) in props.exclusions"
          v-bind:key="index"
        >
          <div class="card-body row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="orange"
              class="bi bi-exclamation-diamond col-1"
              viewBox="0 0 16 16"

            >
              <path
                d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"
              />
              <path
                d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"
              />
            </svg>
            <p class="text-dark mb-0 col-11">
              {{ item }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>